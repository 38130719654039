import api from '../../assets/api.js';
import Vue from "vue";
import moment from 'moment';
import { EventBus } from '../../EventBus.js';

class SendQuote {

  constructor() {
    this.namespaced = true;
    this.state = {
      send_quote_property: {
        id: '',
      },
      propertyUnits: [],
      send_quote_unitIds:[],
      send_quote_spaces:[]
    };

    this.getters = {
      send_quote_property: state => state.send_quote_property,
      propertyUnits: state => state.propertyUnits,
    }

    this.mutations = {
      setProperty: (state, payload) => state.send_quote_property = payload,
      setPropertyUnits: (state, payload) => state.propertyUnits = payload,
    }

    this.actions = {
      async getPropertyInfo({getters, commit, dispatch}, data){
        // Data is a property object

        commit('setProperty', data);
      },
      async getPropertyUnitsInfo({getters, commit, dispatch}, data){
        commit('setPropertyUnits', data);
      },

    }
  }
}


export default SendQuote;
